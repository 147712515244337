* {
    padding: 0px;
    margin: 0px;
}

/*form order css*/
.form-order {
    background-image: url(../public/images/loginback.jpg);
    background-position: center;
    background-size: cover;
    padding: 100px 0px 0px 0px;
}
.price-part {
    display: flex
;
    justify-content: space-between;
}


/*login css start*/
section.login-part {
    background: url(../public/images/banner.jpg);
    /* height: 448px; */
    background-size: cover;
    background-position: right;
    padding: 150px 0px 88px 0px;
}

.signup-form {
    background: var(--white);
    padding: 40px;
    border-radius: 10px;
}

.signup-form input {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #d7d7d7;
    padding: 8px;
    font-size: 14px;
}

.signup-form input[type="submit"] {
    background: var(--red);
    color: var(--white);
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 20px;
}

.signup-form p a {
    color: #f00;
    font-weight: 600;
}

.signup-form p {
    text-align: right;
    font-size: 11px;
}

.signup-form h2 {
    margin-bottom: 30px;
}

.signup-form h2 {
    font-size: 35px;
    color: var(--blue);
    font-weight: bold;
}

.signup-form h2 span {
    color: var(--greeen);
}

/*login css end*/


/*form order css start*/
.form-sub {
    max-width: 820px;
    margin: 0 auto;
}

.form-sub select {
    width: 400px !important;
    padding: 15px 45px;
}

.right-select label {
    padding-left: 42px;
    font-size: 14px;
    margin-bottom: 4px;
}

.form-sub .left-entity {
    top: 26px;
    padding: 17px 14px;
}

.select-box {
    margin-top: 10px;
    font-size: 14px;
}

.button11 button {
    background: var(--red) !important;
    color: #fff !important;
}

.minus-part h6 {
    font-size: 29px;
    color: var(--greeen);
}

.form11 {
    padding: 20px;
    background: var(--blue);
    border-radius: 8px;
}

.form12 {
    display: flex;
    justify-content: space-between;
    color: #fff;
}

.compare-1 .comparion-part1 {
    padding-top: 60px !important;
    padding-bottom: 80px;
}

.selected1 h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

button.continue {
    background: var(--red);
    margin-right: 5px;
    padding: 10px 13px;
    border: 1px solid var(--red);
    color: var(--white);
    border-radius: 4px;
    text-decoration: none;
}

button.basic-btn1 {
    width: 150px;
}

.comparion-part1 table tr td button.btn-red-600 {
    background: var(--red);
    color: #fff;
}

th.active-header {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
    border-left: 1px solid var(--red) !important;
    border-right: 1px solid var(--red) !important;
    border-top: 1px solid var(--red) !important;
    padding: 3px !important;
    padding-bottom: 0px !important;
}

.active-column {
    border-left: 1px solid #FF4A00 !important;
    border-right: 1px solid #FF4A00 !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 80px !important;
    padding-right: 3px !important;
    padding-left: 3px !important;
}

.compare-1 .comparion-part1 table tr {
    border-bottom: 1px solid #ddd;
}

.compare-1 .comparion-part1 table {
    border-collapse: collapse;
    border-spacing: 0 .4em;
}

.active-header {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
    padding: 24px 12px !important;
    background: #FFF6F2 !important;
}

.active-column {
    background: #FFF6F2;
    padding-right: 3px !important;
    padding-left: 3px !important;
}

.comparion-part1 table tr th h2 {
    font-size: 27px;
    font-weight: 600;
    color: var(--greeen);
}

.comparion-part1 table tr th span {
    color: #0A0A0A;
    font-size: 41px;
    font-weight: 600;
}

span.recoomenrded1 {
    font-size: 9px !important;
}

.comparion-part1 table tr th h5 {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}



.comparion-part1 table tr th p {
    font-weight: 400;
}

.comparion-part1 table tr td button.btn-red-600 {
    background: var(--red);
    color: #fff;
}

.comparion-part1 {
    background: var(--lightblue);
    padding: 100px 0px 0px 0px;
}

.comparion-part1 table tr td button {
    background: #fff;
    border: none;
    padding: 10px 6px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: var(--blue);
    width: 80px;
    margin: 0 auto;
}

.form-order-heading1 h1 {
    font-size: 45px;
    color: var(--blue);
    font-weight: bold;
}

.form-order-heading1 h1 span {
    color: var(--greeen);
}

.form-order-heading1 {
    margin-bottom: 40px;
}



button.basic-btn1 {
    background: #e2f7ff;
    border: none;
    padding: 10px;
    width: 150px;
}

.btn-red-600 {
    background-color: red;
    color: white;
}


button.basic-btn2 {
    background: #e2f7ff;
    border: none;
    padding: 10px;
    width: 150px;
}

button.basic-btn3 {
    background: #e2f7ff;
    border: none;
    padding: 10px;
    width: 150px;
}

.down-para {
    display: none;
}

button.downarrow1 img {
    width: 12px;
}

span.check-img {
    display: none;
}

button.downarrow1 {
    padding: 0px 0px !important;
    color: #000 !important;
    font-weight: 400 !important;
}

.btn-red-600 {
    background: var(--red) !important;
    border: none !important;
    color: #fff !important;
    width: 150px !important;
    padding: 10px !important;
}

/*form order css end*/



.toggle-button {
    font-size: 24px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    border: none;
    background-color: #f0f0f0;
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    /* Added margin for better spacing between buttons */
}

.toggle-button .icon {
    display: none;
    font-size: 24px;

}

.select-box button {
    width: 156px !important;
}

.toggle-button .plus-icon {
    display: inline;
}

.toggle-button.active .plus-icon {
    display: none;
}

.toggle-button.active .check-icon {
    display: inline;
    width: 15px;
    height: 15px;
}

.toggle-button:focus {
    outline: none;
}

.nothing {
    max-width: 950px;
    margin: 100px auto;
    text-align: center;
    background: var(--lightblue);
    padding: 100px;
}

.nothing1 h2 {
    font-size: 70px;
    color: var(--red);
}

.nothing1 h4 {
    color: var(--red);
    font-size: 30px;
}













@media only screen and (max-width:768px) {
    .box1 .box11 {
        width: 100%;
        flex-direction: column;
    }

    .box1 {
        flex-direction: column;
    }

    .box1 .box11 {
        text-align: center;
    }

    .box-right p {
        margin-top: 10px;
    }

    .banner-part {
        height: auto;
        background-position: inherit;
        padding: 50px 0px;

    }

    .banner-content h1 {
        font-size: 24px;
    }

    .banner-content {
        position: inherit;
        top: 0;
        padding: 0px 10px;
        text-align: center;
    }

    .select-part {
        position: inherit;
        top: 0;
        max-width: 100%;
        width: 100%;
        left: 0;
    }

    .select-part select {
        width: 100%;
    }

    .packge-btn {
        justify-content: center;
    }

    .comparion-part {
        padding: 40px 0px 0px 0px;
    }

    .comparion-part table tr th h2 {
        font-size: 20px;
        text-align: center;
    }

    .corp {
        padding: 40px 0px;
    }

    .heading-part h2 {
        font-size: 30px;
    }

    .strainght-content {
        text-align: center;
        padding-right: 0px !important;
        border-right: none !important;
    }

    .strght-con1 {
        align-items: center;
        flex-direction: column;
    }

    .strght-con1 {
        margin-bottom: 30px;
    }

    .box-stright {
        padding: 0px 0px;
    }

    .right-stright h5 {
        margin-left: 0px;
        margin-top: 10px;
        font-size: 15px;
    }

    .strainght-content button {
        margin-bottom: 40px;
    }

    .testimoial {
        padding: 40px 15px 100px 0px;
    }

    .review-part {
        display: flex;
        flex-direction: column;
    }

    .footer-logo {
        display: none;
    }

    .footerright {
        padding: 30px 30px;
    }

    .select1 {
        margin-left: 0px;
        padding: 0px;
    }

    .select-part {
        padding: 0px 15px;
    }

    nav.navbar.navbar-expand-lg.bg-body-tertiary.top-nav .container-fluid {
        padding-right: 15px !important;
    }

    .logo {
        padding-left: 0px;
    }

    .btn-part {
        display: flex;
        align-items: center;
        position: absolute;
        top: -49px;
        right: 0;
        margin: 0 auto;
    }

    .phone-part {
        position: absolute;
        top: -51px;
        font-size: 14px;
    }

    .phone1-icon img {
        width: 14px;
    }

    .top-nav {
        padding: 11px 0px;
        margin-top: 64px;
    }

    .login-btn a {
        padding: 15px 20px;
    }

    .Started a {
        padding: 15px 20px;
    }

    div#navbarScroll {
        position: absolute;
        top: 71px;
        background: var(--lightblue);
        left: 0;
        right: 0;
        z-index: 9;
    }
}

.sign-up {
    display: flex;
    gap: 20px;
}

.error-text {
    color: red;
    font-size: 13px;
    padding-left: 4px;
}

/*dashbord css*/
.sidebar {
    height: 100vh;
    background-color: #f7f7f7;
    color: #fff;
    position: fixed;
    width: 250px;
}

.dashbord-logo img {
    width: 200px;
}

.sidebar a {
    color: #adb5bd;
    text-decoration: none;
}

.sidebar a {
    color: #0A0A0A;
    text-decoration: none;
}

.sidebar a:hover {
    color: #fff;
}

.content {
    margin-left: 260px;
    padding: 20px;
}

.dashboard-tab .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background: var(--red);
}

h4.mb-4.dashborad-heading {
    margin-top: 30px;
    color: var(--greeen);
}

.dashboard-tab .nav-pills .nav-link {
    border-radius: 0px;
}

.dashboard-tab a.nav-link {
    margin-bottom: 2px;
}

.dashboard-tab nav a i {
    padding-right: 7px;
}

.dashboard-tab .nav a:hover {
    background: var(--lightblue);
    color: #000;
}

.dashboard-tab nav a {
    margin-bottom: 7px;
}

.detail-user {
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    margin-top: 6px;
    padding: 20px;
    background: #e2f7ff4a;
    margin-bottom: 30px;
}

.form-detila {
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);

    padding: 30px;
    border-radius: 30px;
}

.form-detila button[type="submit"] {
    background: var(--red);
    margin-right: 5px;
    padding: 14px 30px;
    border: 1px solid var(--red);
    color: var(--white);
    border-radius: 4px;
    text-decoration: none;
    margin-top: 30px;
    font-weight: 600;
    text-transform: uppercase;
}

.profile-table table {
    overflow-x: auto;
    width: 100%;
    border-collapse: collapse;
}

.profile-table table thead {
    background-color: #00aeef;
}

.profile-table table th {
    font-weight: 600;
    color: #fff;
}

.profile-table tr td,
th, td {
    border-left: 1px solid #ddd;
    padding: 10px;
    vertical-align: text-bottom;
}

button.view-detail-pop {
    background: var(--greeen);
    border: navajowhite;
    color: #fff;
    padding: 10px 15px;
    border-radius: 4px;
}

#myModal1 .modal-dialog {
    max-width: 1000px;
}

.table-container {
    overflow-x: auto;
    width: 100%;
}

.detail-table {
    width: 100%;
}

.detail-table table tr th {
    vertical-align: middle;
}

.detail-table table {
    width: 100%;
    table-layout: fixed;
}

.company-info {
    font-size: 18px;
    font-weight: 600;
    color: var(--red);
}

.view-detailin {
    background: #f7f7f7;
    padding: 20px;
    margin-top: 10px;
}

.detail-table tr td {
    border: 1px solid #ddd;
    padding: 10px;
    font-size: 14px;
}

.detail-table p {
    margin-bottom: 0px;
}

.detail-table tr th,
td {
    vertical-align: middle;
}

.user-logout i {
    background: var(--lightblue);

    border-radius: 100%;
    height: 60px;
    width: 60px;
    line-height: 60px;
    text-align: center;
}

.user-logout button {
    background: var(--red);
    margin-right: 5px;
    padding: 14px 30px;
    border: 1px solid var(--red);
    color: var(--white);
    border-radius: 4px;
    text-decoration: none;
    margin-top: 30px;
    font-weight: 600;
    text-transform: uppercase;
    margin-left: 20px;
}

/* Add this to your CSS file */
.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    text-transform: capitalize;
}
.contact-form p.error-message {
    color: #ff1a1a;
    font-weight: bold;
    font-size: 14px;
}
.contact-form .success-message {
    color: green;
    font-size: 16px;
    font-weight: bold;
}
.input-error {
    border-color: red;
}

.modal-content {
    width: 140% !important;
}

.selected1 h4 {
    font-family: 'Antonio' !important;
    margin-bottom: 16px;
    color: var(--red);
}

.common-banenr {
    background-image: url(../public/images/abtbanner.jpg);
    height: 150px;
    background-position: center;
    position: relative;
}

.common-content h1 {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.common-content h1 {
    font-size: 45px;
    color: var(--blue);
    font-weight: bold;
}

.common-content h1 span {
    color: var(--greeen);
}

.abt-section1 {
    background-color: #f7f7f7;
    padding: 40px 0px;
    border-top: 2px solid var(--red);
}

.abt-section1 h2 {
    margin-bottom: 20px;
    font-size: 34px;
    font-weight: 600;
    color: var(--greeen);
    text-transform: capitalize;
}

.connect-btn a {
    background-color: var(--blue);
    border-radius: 4px;
    text-decoration: none;
    padding: 14px 13px;
    border: 1px solid var(--blue);
    color: var(--white);
}

.connect-btn {
    margin-top: 50px;
}

.mask-content.abt-con p {
    text-align: center !important;
}

.mask-content.abt-con {
    min-height: 100px;
}

.get-touch-ab {
    background-color: var(--greeen);
    text-align: center;
    padding: 50px;
}

.get-touch-ab h5 {
    color: #fff;
}

.get-touch-ab p {
    color: var(--white);
}

.whatdo {
    margin-top: 50px;
}

.whatdo ul li i {
    position: absolute;
    left: 0;
    top: 4px;
    color: var(--red);
}

.abt-section1.abt-value img {
    width: 100%;
}

.abt-section1.abt-value ul li {
    position: relative;
    list-style: none;
    padding-left: 30px;
    margin-bottom: 10px;
}

.abt-section1.abt-value ul li i {
    position: absolute;
    left: 0;
    top: 4px;
    color: var(--red);
}

.abt-section1.abt-value ul {
    padding-left: 0px;
}

.whatdo ul {
    padding-left: 0px;
}

.abt-section1.meet-founder {
    background-color: var(--lightblue);
    padding: 60px 0px;
    border-top: 2px solid #d8115900;
}

.get-touch-ab.ourvision {
    background: var(--red);
}

.abt-section1.Entrepreneurial ul li a {
    color: #000;
}

.abt-section1.Entrepreneurial p a {
    color: #0083F5;
    font-weight: 600;
}
.abt-section1.cancellation-policy a {
    color: var(--red);
    font-weight: 600;
    text-decoration: none;
}
.right-next button {
    border-radius: 0px;
}
.register-left .accordion-header {
    margin-bottom: 0;
    font-family: inherit;}
    div#selectedFreeYear .accordion-header button {
        font-weight: 600;
    }  
    .comparion-part1 table tr td.active-column button.toggle-button {
      
   
    } 
    .comparion-part1 table tr td.active-column button.toggle-button.active {
        background: var(--greeen);
        border: 1px solid var(--greeen);
        color: var(--white);
        box-shadow: none;

    }
    .form11 ul {
        padding-left: 0px;
        list-style: none;
    }
    .abt-section1.Entrepreneurial ul li a {
        font-weight: 600;
        color: #0083F5;
    }
    .amount-part {
        display: flex
    ;
        justify-content: space-between;
    }
.abt-section1.cancellation-policy a:hover {
    text-decoration: underline;
}
.abt-section1.Entrepreneurial ul li i {
    position: absolute;
    left: 0;
    color: var(--red);
    top: 4px;
}

.abt-section1.Entrepreneurial ul li {
    margin-top: 10px;
    padding-left: 26px;
    position: relative;
}

.abt-section1.Entrepreneurial ul {
    padding-left: 0px;
    list-style: none;
}

.whatdo ul li {
    position: relative;
    list-style: none;
    padding-left: 30px;
    margin-bottom: 10px;

}

.corp p {
    max-width: 700px;
    margin: 0 auto;
    font-weight: 600;
}

.get-touch-ab h2 {
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 600;
    color: var(--white);
}

.connect-btn2 a {
    background-color: var(--white);
    border-radius: 4px;
    text-decoration: none;
    padding: 14px 20px;
    border: 1px solid var(--white);
    color: #000;
}

.abt-section1.abt-section2 {
    border-top: none;
}

section.contact-us {
    background-color: #f7f7f7;
    padding: 60px 0px;
    border-top: 2px solid var(--red);
}

.contact-box {
    background: #d81159;
    padding: 20px;
    min-height: 137px;
    text-align: center;
}

.contact-box {
    background: #fff;
    padding: 20px;
    min-height: 203px;
    text-align: center;
    box-shadow: 0px 0px 9px 3px #1a1a1a08;
}

.contact-box p a {
    color: #000;
    font-size: 17px;
}

.contact-form {
    max-width: 650px;
    margin: 50px auto 50px auto;
    background: #00aeef;
    padding: 20px;
}

.contact-form input {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 11px;
    font-size: 14px;
    color: #000;
    border: none;
}

.contact-form textarea {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 11px;
    font-size: 14px;
    color: #000;
    border: none;
    height: 100px;
}

.form-contact button[type="submit"] {
    background: var(--red);
    margin-right: 5px;
    padding: 14px 13px;
    border: 1px solid var(--red);
    color: var(--white);
    border-radius: 4px;
    text-decoration: none;
    margin-top: 16px;
}

.form-contact h4 {
    text-align: center;
    margin-bottom: 30px;
    color: #fff;
    font-size: 37px;
}

.abt-section1 img {
    width: 100%;
}

.whatdo img {
    width: 100%;
}
.afflicate-detail {
    margin-top: 25px;
}
.afflicate-part {
    max-width: 950px;
    margin: 0 auto;
    text-align: center;
}
.Afflicate-box h5 a {
    color: var(--red);
    text-decoration: none;
}
.Afflicate-box .mask-content {
    min-height: 276px;
}
.corp.join-afflicated p {
    font-weight: 500;
}
.abt-section1.testimonal h2 {
    text-align: center;
}
.box-reviews {
    background: #fff;
    padding: 20px;
    min-height: 170px;
    box-shadow: 0px 0px 3px 0px #dddddd78;
}
.abt-section1.stay-cnnected {
    text-align: center;
}
.abt-section1.cancellation-policy h2 {
    text-transform: capitalize;
    font-size: 29px;
}
.abt-section1.cancellation-policy h4 {
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 20px;
    font-weight: 600;
    font-family: sans-serif;
}
.Afflicate-box img {
    width: 100%;
}
.services-page-box p {
    font-weight: 400;
}
.services-page-box .Afflicate-box .mask-content {
    min-height: 300px;
}
.services-page-box .Afflicate-box .mask-content.mask-content1 {
    min-height: 260px;
}
.link-service a {
    background: var(--red);
    padding: 10px 20px;
    text-decoration: none;
}
.link-service a:hover{
    background-color: var(--blue);
}

.link-service {
    margin-top: 30px;
}
.services-page-box .Afflicate-box .mask-content.mask-content2 {
    min-height: 263px;
}
.Afflicate-box {
    margin-top: 12px;
}
.corp.box-services h2 {
    margin-bottom: 30px;
}
.get-touch-ab.take-first a {
    color: #fff;
    margin: 0px 10px;
}
.services-page-box .Afflicate-box  .mask-content.digitalmarketing2 {
    min-height: 168px;
}
.mask-group p {
    font-weight: 400;
}
.form-contact input[type="submit"] {
    background: var(--red);
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
}
@media only screen and (max-width:768px) {
    .get-touch-ab {
        padding: 40px 0px;
    }
    .services-page-box .Afflicate-box .mask-content.mask-content1 {
        min-height: auto;
    }
    .services-page-box .Afflicate-box .mask-content.mask-content2 {
        min-height: auto;
    }
    .abt-section1.abt-value img {
        display: none;
    }
   
    .abt-section1 h2 {
        font-size: 22px;
    }

    .get-touch-ab h2 {
        font-size: 22px;
    }

    .heading-part h2 {
        font-size: 22px;
    }

    .common-content h1 {
        font-size: 30px;
    }

    .common-banenr {
        background-image: url(https://corpquick.com/static/media/abtbanner.76155fe….jpg);
        height: 80px;
    }
    .Afflicate-box .mask-content {
        min-height: auto;
    }
    .abt-section1 {
        padding: 40px 0px;}
     
        .services-page-box .Afflicate-box .mask-content {
            min-height: auto;
        }
        .services-page-box .Afflicate-box .mask-content.digitalmarketing2 {
            min-height: auto;
        }
        .form-sub select {
            width: 100% !important;
        }

}
span.star.full {
    color: #efef7e;
    font-size: 29px;
}
span.star.empty {
    font-size: 27px;
}