:root {
    --blue: #00AEEF;
    --greeen: #39B54A;
    --white: #ffffff;
    --red: #D81159;
    --black: #000000;
    --orange: #FF8800;
    --dark-blue: #0083F5;
    --lightblue: #e2f7ff;
}

body {
    font-family: "Poppins", sans-serif;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4 {
    font-family: "Antonio", sans-serif;
}

section.registration-form {
    background-color: var(--lightblue);
    padding: 100px 0px;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.label-part {
    display: flex;
    justify-content: space-between;
}

.label-part {
    position: relative;
}

span.help-block-error.hide {
    font-size: 11px;
    color: #f00;
}

.registration-from {
    background-color: var(--lightblue);
    padding: 100px 0px;
}

.box-content select {
    width: 100%;
    padding: 11px;
    font-size: 14px;
    border-radius: 10px;
    color: var(--black);
    padding-left: 20px;
    margin-top: 5px;
    border: 1px solid #ddd;
}

.box-content input {
    width: 100%;
    padding: 11px;
    font-size: 14px;
    border-radius: 10px;
    color: var(--black);
    padding-left: 20px;
    margin-top: 5px;
    border: 1px solid #ddd;
}

.box__heading {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
}

.label-part i {
    position: absolute;
    right: 0;
    top: 4px;
}

.button-nest-prev {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.tell-us2 i {
    margin-left: 23px;
}

.tell-us2 p {
    margin-bottom: 0px;
}

.tell-us2 {
    display: flex;
    align-items: center;
    position: relative;
}

.register-left {
    background: var(--white);
    padding: 40px 20px;
    border-radius: 10px;
}

.companynameCoose p {
    margin-bottom: 7px;
    font-size: 15px;
}

.companynameCoose {
    background: #f7f7f7;
    margin-top: 30px;
    padding: 10px;
    margin-bottom: 30px;
}

.company-namedisplay label {
    font-size: 14px;
}

div#final-name {
    max-width: 300px;
    background: var(--red);
    padding: 10px;
    background: var(--red);
    margin-right: 5px;
    padding: 10px 13px;
    border: 1px solid var(--red);
    color: var(--white);
    border-radius: 4px;
    margin: 10px 0px;
}

.business-purpose label {
    font-size: 14px;
    margin-bottom: 10px;
}

.business-purpose textarea {
    height: 100px;
    font-size: 14px;
}

.business-purpose {
    margin-top: 20px;
}

.tell-us {
    margin-top: 20px;
}

.tell-us2 p {
    font-size: 14px;
    font-weight: 500;
}

.para-tell p {
    font-size: 14px;
    color: #b2acac;
}

.left-next a {
    /* background: var(--red); */
    margin-right: 5px;
    /* padding: 13px 20px; */
    /* border: 1px solid var(--red); */
    color: var(--white);
    text-decoration: none;
}

.right-next a {
    background: var(--blue);
    margin-right: 5px;
    padding: 10px 20px;
    border: 1px solid var(--blue);
    color: var(--white);
    text-decoration: none;

}

/* .right-next.nex2 a{
        opacity: 0.4;
    } */

.left-next a i {
    padding-right: 7px;
}

.order-items .item {

    display: flex;
}

.order-items .item {
    display: flex;
    justify-content: space-between;
}

.register-right {
    background: var(--white);
    padding: 40px;
    border-radius: 10px;
}

.register-right h4 {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.register-right .progress {
    height: 37px;
    BORDER-RADIUS: 30px;
    --bs-progress-bar-bg: var(--greeen) !important;
    margin-bottom: 30px;
}

.item {
    margin-top: 10px;
    font-size: 15px;
}

.order-items .item {
    display: flex;
    justify-content: space-between;
}

.register-right .item i {
    color: var(--orange);
}

.totla-value1 .name {
    font-weight: 600 !important;
    font-size: 20px !important;
}

.totla-value1 .value {
    font-weight: 600 !important;
    font-size: 20px !important;
}

.trustpilot {
    text-align: center;
}

.trustpilot img {
    width: 100%;
    max-width: 100px;
}

.shopper-approved img {
    width: 100%;
}

.shopper-approved ul li i {
    color: #d8a716;
}

.shopper-approved ul li {
    display: inline;
}

.shopper-approved ul {
    padding-left: 0px;
}

.shopper-approved {
    text-align: center;
}

.join-business {
    background: #fff4f4;
    padding: 20px;
    border-radius: 4px;
}

.join-business h2,
h3 {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.join-business p.tracking-wide {
    margin: 4px 0px;
    font-weight: 600;
    color: var(--red);
}

.right-next button {
    background: var(--blue);
    margin-right: 5px;
    padding: 10px 20px;
    border: 1px solid var(--blue);
    color: var(--white);
    text-decoration: none;
}


.join-business h6 {
    font-size: 12px;
    margin-top: 10px;
    font-weight: 400;
    line-height: 18px;
}

.trustpilot {
    text-align: center !important;
    margin: 15px auto;
}

.custom-radio {
    display: flex;
}

.custom-radio {
    display: flex;
    justify-content: space-between;
    border: 1px solid #e5e5e5;
    border-radius: 24px;
    padding: 24px;
    position: relative;
    transition: border-color .3s ease-in;
    width: 100%;
    cursor: pointer;
}

.radio-center p,
h6,
h5 {
    margin-bottom: 4px;
}

.radio-center p {
    font-size: 15px;
    font-weight: 600;
}

.radio-center h6 a {
    font-size: 14px;
    color: var(--black);
    text-decoration: none;
}

.radio-center h5 {
    font-size: 14px;
}

.para12 {
    margin-top: 30px;
    font-size: 16px;
}

.radio-part {
    margin-top: 30px;
}

.fast1 a {
    border: 2px solid var(--orange);
    padding: 6px 20px;
    color: var(--orange);
    font-weight: 600;
    text-decoration: none;
    background-color: var(--white);
}

.fast1 {
    position: absolute;
    top: -14px;
    right: 38%;
}

.radio-part {
    margin-top: 50px;
}

.custom-radio.activated {
    border-color: var(--orange);
}

.custom-radio.activated label:after {
    opacity: 1;
}

.register-right h2 {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
}

.conact-form label {
    display: block;
}

.conact-form input {
    width: 100%;
    padding: 11px;
    font-size: 14px;
    border-radius: 10px;
    color: var(--black);
    padding-left: 20px;
    margin-top: 5px;
    border: 1px solid #ddd;
}

.conact-form label {
    margin-top: 10px;
}

.select-box11 select {
    position: absolute;
    top: 6px;
    font-size: 14px;
    padding: 11px;
    border-radius: 4px 0px 0px 4px;
    border: none;
    left: 0px;
    background: var(--red);
    color: #fff;
}

.select-box11 input[type="text"] {
    padding-left: 150px;
}

.select-box11 {
    position: relative;
}


.checl-box12 {
    margin-top: 20px;
}

.checl-box12 span {
    padding-left: 20px;
}

.checl-box12 input {
    position: absolute;
    left: -340px;
}

.checl-box12 {
    position: relative;
}

.checl-box12 p {
    padding-left: 20px;
}


.custom-radio input[type="radio"] {
    display: none;
    opacity: 0;
}

.custom-radio label {
    flex-shrink: 0;
    height: 24px;
    margin: 0;
    padding: 0;
    position: relative;
    width: 24px;
}

.custom-radio label:before {
    position: absolute;
    content: "";
    background-color: #fafafa;
    border: 1px solid #e5e5e5;
    height: 24px;
    left: 0;
    transition: border .15s ease-in-out;
    width: 24px;
    border-radius: 30px;
}



.custom-radio label:after {
    position: absolute;
    content: "";
    height: 10px;
    left: 7px;
    width: 10px;
    background: var(--orange);
    opacity: 0;
}

.custom-radio input[type=radio]:checked+label:after {
    background-color: #ff4a00;
}

.custom-radio label:after,
.custom-radio label:before {
    border-radius: 50%;
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) !important;
}

#order-item-expedited {
    display: none;
}

.select-box11 input {
    padding: 20px 50px !important;
    width: 100% !important;
    border-radius: 10px !important;
    border: 1px solid #ddd !important;
}

.select-box11 {
    margin-top: 9px !important;
}

.list {
    list-style: none;
    padding-left: 0;
}

.list li {
    margin-bottom: 12px;
}

.list .fa {
    margin-right: 10px;
    color: #ff4a00;
}


.large-box .spacing{
    padding-bottom: 2rem;
    padding-top: 2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.large-box .custom-radio {
    border: 0px solid #e5e5e5;
    border-right: 1px solid #FF4A00;
    border-bottom: 1px solid #FF4A00;
    border-left: 1px solid #FF4A00;
    background: var(--gradient-orange-1, linear-gradient(239deg, rgba(255, 255, 255, 0.20) 8.52%, rgba(255, 255, 255, 0.00) 51.09%), #FF4A00);
    border-radius: 0px 0px 24px 24px;
    padding: 16px;
}
.large-box .custom-radio label{
    margin: auto;
}

.large-box h4 {
    font-size: 21px;
    line-height: 2rem;
    font-weight: 600;
    max-width: 260px;
    font-family: 'Poppins';
}

.large-box p {
    text-align: center !important;
    color: #737373;
    margin: 10px 0 15px;
}

.large-box h5 {
    font-size: 18px;
    font-weight: 700;
}


.large-box .list {
    margin-top: 10px;
}

.large-box .list li {
    text-align: left;
    padding-left: 25px;
    position: relative;
    font-size: 14px;
}

.large-box .list .fa {
    position: absolute;
    left: 0;
    top: 5px;
}

.large-box .amount {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 1.5rem;
}

.large-box h3 {
    font-size: 14px;
}
.large-box .custom-radio.no-bg{
    border: 0px solid #e5e5e5;
    border-right: 1px solid #FF4A00;
    border-bottom: 1px solid #FF4A00;
    border-left: 1px solid #FF4A00;
    background: var(--gradient-orange-1, linear-gradient(239deg, rgba(255, 255, 255, 0.20) 8.52%, rgba(255, 255, 255, 0.00) 51.09%), #FF4A00);
    border-radius: 0px 0px 24px 24px;
    padding: 16px;

    text-align: center;
    border-radius: 0 0 24px 24px;
    border-right: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    border-left: 1px solid #f4f4f4;
    position: absolute;
    bottom: 0;
}
.price-check {
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 1rem;
}
.service-package-box {
    display: flex;
    gap: 1rem;
}
.large-box h6 {
    margin-bottom: 30px;
    margin-top: 30px;
}
.mt-3{
    margin-top: 3rem;
}
.alert-box{
    align-items: flex-start;
    border: 1px solid var(--gray-200, #f5f5f5);
    border-radius: 16px;
    color: #4e4e4e;
    display: flex;
    gap: 24px;
    margin-bottom: 0;
    padding: 16px;
    background-color: #fafafa;
}
.alert-icon {
    align-items: center;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, .05);
    display: flex;
    flex-shrink: 0;
    gap: 8px;
    height: 48px;
    justify-content: center;
    padding: 4px;
    width: 48px;
}
.alert-title {
    color: #1c1b18;
    font-size: 18px;
}
.alert-content p {
    color: #434343;
}
.box__heading2{
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 600;
}
.service-package-content p a{
    color: #ff4a00;
    text-decoration: none;
    font-weight: 500;    
}
.service-package-title{
   color: #1c1b18;
   font-weight: 700;
}
.service-package-content p {
    color: #737373;
}
.service-package-content p span{
    font-weight: 700;
}
.modal-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6px 0px;
    font-size: 14px;
    padding-left: 0;
    list-style: none;
}
.modal-list li {
    position: relative;
    padding-left: 15px;
    color: #737373;
}
.modal-list li .fa {
    position: absolute;
    left: 0;
    top: 4px;
}
.registerformstart p {
    color: var(--black);
}
.new-tbn11 button a {
    color: var(--red);
    font-weight: 600;
}

.new-tbn11 button {
    background: transparent;
    border: none;
}
.new-tbn11 {
    text-align: right;
    margin-top: 26px;
}
ul.modal-list li {
    color: var(--black);
}
.large-box.borderactivated {
    border-color: var(--orange);
}
.form11 h3 {
    color: #fff;
}
.form11 ul li {
    color: #fff;
}
.form11 button.continue {
    background: var(--red);
    color: var(--white);
    margin-right: 5px;
    padding: 10px 15px;
   width: 120px;
}

.companynameCoose p {
    margin-bottom: 7px;
    font-size: 15px;
}

.companynameCoose {
    background: #f7f7f7;
    margin-top: 30px;
    padding: 10px;
    margin-bottom: 30px;
}

.company-namedisplay label {
    font-size: 14px;
}

div#final-name {
    max-width: 300px;
    background: var(--red);
    padding: 10px;
    background: var(--red);
    margin-right: 5px;
    padding: 10px 13px;
    border: 1px solid var(--red);
    color: var(--white);
    border-radius: 4px;
    margin: 10px 0px;
}

.business-purpose label {
    font-size: 14px;
    margin-bottom: 10px;
}

.business-purpose textarea {
    height: 100px;
    font-size: 14px;
}

.business-purpose {
    margin-top: 20px;
}

.tell-us {
    margin-top: 20px;
}

.tell-us2 p {
    font-size: 14px;
    font-weight: 500;
}

.para-tell p {
    font-size: 14px;
    color: #b2acac;
}



.right-next a {
    background: var(--blue);
    margin-right: 5px;
    padding: 10px 20px;
    border: 1px solid var(--blue);
    color: var(--white);
    text-decoration: none;

}

/* .right-next.nex2 a{
        opacity: 0.4;
    } */

.left-next a i {
    padding-right: 7px;
}

.order-items .item {

    display: flex;
}

.order-items .item {
    display: flex;
    justify-content: space-between;
}

.register-right {
    background: var(--white);
    padding: 40px;
    border-radius: 10px;
}

.register-right h4 {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.register-right .progress {
    height: 37px;
    BORDER-RADIUS: 30px;
    --bs-progress-bar-bg: var(--greeen) !important;
    margin-bottom: 30px;
}

.item {
    margin-top: 10px;
    font-size: 15px;
}

.order-items .item {
    display: flex;
    justify-content: space-between;
}

.register-right .item i {
    color: var(--orange);
}

.totla-value1 .name {
    font-weight: 600 !important;
    font-size: 20px !important;
}

.totla-value1 .value {
    font-weight: 600 !important;
    font-size: 20px !important;
}

.trustpilot {
    text-align: center;
}

.trustpilot img {
    width: 100%;
    max-width: 100px;
}

.shopper-approved img {
    width: 100%;
}

.shopper-approved ul li i {
    color: #d8a716;
}

.shopper-approved ul li {
    display: inline;
}

.shopper-approved ul {
    padding-left: 0px;
}

.shopper-approved {
    text-align: center;
}

.join-business {
    background: #fff4f4;
    padding: 20px;
    border-radius: 4px;
}

.join-business h2,
h3 {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
}

.join-business p.tracking-wide {
    margin: 4px 0px;
    font-weight: 600;
    color: var(--red);
}

.right-next button {
    background: var(--blue);
    margin-right: 5px;
    padding: 10px 20px;
    border: 1px solid var(--blue);
    color: var(--white);
    text-decoration: none;
}


.join-business h6 {
    font-size: 12px;
    margin-top: 10px;
    font-weight: 400;
    line-height: 18px;
}

.trustpilot {
    text-align: center !important;
    margin: 15px auto;
}

.custom-radio {
    display: flex;
}

.custom-radio {
    display: flex;
    justify-content: space-between;
    border: 1px solid #e5e5e5;
    border-radius: 24px;
    padding: 24px;
    position: relative;
    transition: border-color .3s ease-in;
    width: 100%;
    cursor: pointer;
}

.radio-center p,
h6,
h5 {
    margin-bottom: 4px;
}

.radio-center p {
    font-size: 15px;
    font-weight: 600;
}

.radio-center h6 a {
    font-size: 14px;
    color: var(--black);
    text-decoration: none;
}

.radio-center h5 {
    font-size: 14px;
}

.para12 {
    margin-top: 30px;
    font-size: 16px;
}

.radio-part {
    margin-top: 30px;
}

.fast1 a {
    border: 2px solid var(--orange);
    padding: 6px 20px;
    color: var(--orange);
    font-weight: 600;
    text-decoration: none;
    background-color: var(--white);
}

.fast1 {
    position: absolute;
    top: -14px;
    right: 38%;
}

.radio-part {
    margin-top: 50px;
}

.custom-radio.activated {
    border-color: var(--orange);
}

.custom-radio.activated label:after {
    opacity: 1;
}

.register-right h2 {
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
}

.conact-form label {
    display: block;
}

.conact-form input {
    width: 100%;
    padding: 11px;
    font-size: 14px;
    border-radius: 10px;
    color: var(--black);
    padding-left: 20px;
    margin-top: 5px;
    border: 1px solid #ddd;
}

.conact-form label {
    margin-top: 10px;
}

.select-box11 select {
    position: absolute;
    top: 6px;
    font-size: 14px;
    padding: 11px;
    border-radius: 4px 0px 0px 4px;
    border: none;
    left: 0px;
    background: var(--red);
    color: #fff;
}

.select-box11 input[type="text"] {
    padding-left: 150px;
}

.select-box11 {
    position: relative;
}


.checl-box12 {
    margin-top: 20px;
}

.checl-box12 span {
    padding-left: 20px;
}



.checl-box12 {
    position: relative;
}

.checl-box12 p {
    padding-left: 20px;
}


.custom-radio input[type="radio"] {
    display: none;
    opacity: 0;
}

.custom-radio label {
    flex-shrink: 0;
    height: 24px;
    margin: 0;
    padding: 0;
    position: relative;
    width: 24px;
}

.custom-radio label:before {
    position: absolute;
    content: "";
    background-color: #fafafa;
    border: 1px solid #e5e5e5;
    height: 24px;
    left: 0;
    transition: border .15s ease-in-out;
    width: 24px;
    border-radius: 30px;
}



.custom-radio label:after {
    position: absolute;
    content: "";
    height: 10px;
    left: 7px;
    width: 10px;
    background: var(--orange);
    opacity: 0;
}

.custom-radio input[type=radio]:checked+label:after {
    background-color: #ff4a00;
}

.custom-radio label:after,
.custom-radio label:before {
    border-radius: 50%;
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) !important;
}

#order-item-expedited {
    display: none;
}

.select-box11 input {
    padding: 20px 50px !important;
    width: 100% !important;
    border-radius: 10px !important;
    border: 1px solid #ddd !important;
}

.select-box11 {
    margin-top: 9px !important;
}

.list {
    list-style: none;
    padding-left: 0;
}

.list li {
    margin-bottom: 12px;
}

.list .fa {
    margin-right: 10px;
    color: #ff4a00;
}

.large-box {
    border: 1px solid #e5e5e5;
    position: relative;
    border-radius: 24px;
    text-align: center;
    min-height: 725px;

}
.large-box .spacing{
    padding-bottom: 2rem;
    padding-top: 2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.large-box .custom-radio {
    border: 0px solid #e5e5e5;
    border-right: 1px solid #FF4A00;
    border-bottom: 1px solid #FF4A00;
    border-left: 1px solid #FF4A00;
    background: var(--gradient-orange-1, linear-gradient(239deg, rgba(255, 255, 255, 0.20) 8.52%, rgba(255, 255, 255, 0.00) 51.09%), #FF4A00);
    border-radius: 0px 0px 24px 24px;
    padding: 16px;
}
.large-box .custom-radio label{
    margin: auto;
}

.large-box h4 {
    font-size: 21px;
    line-height: 2rem;
    font-weight: 600;
    max-width: 260px;
    font-family: 'Poppins';
}

.large-box p {
    text-align: center !important;
    color: #737373;
    margin: 10px 0 15px;
}

.large-box h5 {
    font-size: 18px;
    font-weight: 700;
}

small {}

.large-box .list {
    margin-top: 10px;
}

.large-box .list li {
    text-align: left;
    padding-left: 25px;
    position: relative;
    font-size: 14px;
}

.large-box .list .fa {
    position: absolute;
    left: 0;
    top: 5px;
}

.large-box .amount {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 1.5rem;
}

.large-box h3 {
    font-size: 14px;
}
.errorMsg img {
    padding-right: 3px;
}
.large-box .custom-radio.no-bg{

    text-align: center;
    border-radius: 0 0 24px 24px;
    border-right: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    border-left: 1px solid #f4f4f4;
    position: absolute;
    bottom: 0;
}
.price-check {
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 1rem;
}
.service-package-box {
    display: flex;
    gap: 1rem;
}
.large-box h6 {
    margin-bottom: 30px;
    margin-top: 30px;
}
.mt-3{
    margin-top: 3rem;
}
.alert-box{
    align-items: flex-start;
    border: 1px solid var(--gray-200, #f5f5f5);
    border-radius: 16px;
    color: #4e4e4e;
    display: flex;
    gap: 24px;
    margin-bottom: 0;
    padding: 16px;
    background-color: #fafafa;
}
.alert-icon {
    align-items: center;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, .05);
    display: flex;
    flex-shrink: 0;
    gap: 8px;
    height: 48px;
    justify-content: center;
    padding: 4px;
    width: 48px;
}
.alert-title {
    color: #1c1b18;
    font-size: 18px;
}
.alert-content p {
    color: #434343;
}
.box__heading2{
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 600;
}
.service-package-content p a{
    color: #ff4a00;
    text-decoration: none;
    font-weight: 500;    
}
.service-package-title{
   color: #1c1b18;
   font-weight: 700;
}
.service-package-content p {
    color: #737373;
}
.service-package-content p span{
    font-weight: 700;
}
.modal-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6px 0px;
    font-size: 14px;
    padding-left: 0;
    list-style: none;
}
.modal-list li {
    position: relative;
    padding-left: 15px;
    color: #737373;
}
.modal-list li .fa {
    position: absolute;
    left: 0;
    top: 4px;
}
.registerformstart p {
    color: var(--black);
}
.new-tbn11 button a {
    color: var(--red);
    font-weight: 600;
}

.new-tbn11 button {
    background: transparent;
    border: none;
}
.new-tbn11 {
    text-align: right;
    margin-top: 26px;
}
ul.modal-list li {
    color: var(--black);
}
.large-box.borderactivated {
    border-color: var(--orange);
}
.box__heading1 {
    font-size: 24px;
}

/*process-memnbers*/
.dynamic-div {
    border: 1px solid #000;
    padding: 10px;
    margin: 5px;
}
.edit-form {
    display: none;
    margin-top: 10px;
}

.custum-checkbox {
    display: flex;
    position: relative;
}
.left-checkbox input[type="checkbox"] {
    width: 25px;
    height: 25px;
}
.right-checkbox {
    padding-left: 17px;
}
.right-checkbox p i {
    font-size: 20px;
}
.dynamic-div {
    border: 1px solid #f0eeee;
    padding: 40px 20px;
    margin: 5px;
    background: #f3fafc;
    border-radius: 20px;
}
input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    margin-right: 10px;
}

/* Default color when checked (blue) */
input[type="checkbox"]:checked {
    background-color: #ff8800; /* orange */
    border-color: #ff8800;
}

/* Checkmark symbol inside the checkbox */
input[type="checkbox"]:checked::before {
    content: '✔';
    color: white;
    font-weight: bold;
    position: absolute;
    left: 4px;
    top: 0px;
    font-size: 14px;
}
select#numDivs {
    width: 100%;
    margin: 10px 0px;
    padding: 11px;
    border: 1px solid #ddd;
    border-radius: 7px;
}
.dynamic-div h2 {
    margin-bottom: 60px;
    font-family: 'Poppins';
    font-size: 20px;
    position: relative;
}
/* .dynamic-div h2:before {
    position: absolute;
    content: "";
    bottom: -12px;
    width: 100%;
    height: 1px;
    background: #e0dada;
    left: 0;
    right: 0;
} */
.other-info h4 {
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
}

.other-info {
    margin: 22px 0px;
}
.select-role {
    width: 50%;
    /* width: 100%; */
    margin: 10px 0px;
    padding: 11px;
    border: 1px solid #ddd;
    border-radius: 7px;
}

.form-section label {
    display: block;
    font-family: 'Poppins';
    margin-top: 20px;
}
.form-section input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
}
.other-information h4 {
    font-family: 'Poppins';
    font-size: 20px;
    margin-bottom: 20px;
}
.register-left22 {
    margin-top: 30px;
}

.mb25{
    margin-bottom:25px;
}
#agentInformation, #companyNameBlock{
    display: none;
}
.moreContent{
    display: none;
}
.showHide{
    display: block;
}
#infCompanyAddress {
    display: none;
}

#ITINBlock {
    display: none;
}

.foreign-individual,
.identification-type {
    gap: 15px
}

.foreign-individual>div,
.identification-type>div {
    flex: 1;
}

.foreign-individual label,
.identification-type label {
    width: 100%;
}
.or {
    position: relative;
    text-align: center;
}
.or span{
    background: #fff;
    position: relative;
    padding: 0 20px;
}
.or:before {
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background: #e3e3e3;
    content: "";
    top: 50%;
    transform: translateY(-50%);
}
button.close{
    background: none;
    border: 0;
}
.common-border-radius-blk{
    border: 1px solid rgb(231 231 231/1);
    border-radius: 8px;
}
.customaccordion{
    display: none;
}
.imageBg{
    background: #e2e2e2;
    min-width: 200px;
    text-align: center;
}
.font20{
    font-size: 20px;
}
.fontWeight700{
    font-weight: 700;
}
.business-ownwes-blk{
    border-top: 1px dotted #e2e2e2;
    display: flex;
    justify-content: space-between;
}
.threeBox {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 14px;
}
#additionalExplanation, #customCompanyAddess{
    display: none;
}

div#navbarScroll ul {
    text-align: center;
    margin: 0 auto;
}

.phone-part {
    display: flex;
    align-items: center;
}

.btn-part {
    display: flex;
    align-items: center;
}

.login-btn a {
    padding: 22px 20px;
    background-color: var(--blue);
    color: var(--white);
    text-decoration: none;
}

.Started a {
    padding: 22px 20px;
    background-color: var(--greeen);
    color: var(--white);
    text-decoration: none;
}

.login-btn {
    margin-right: 1px;
}

.top-nav {
    padding: 0px 0px;
}

.phone-part {
    margin-right: 10px;
}

.phone1-icon {
    padding-right: 10px;
}

.phone1-text a {
    color: var(--red);
    text-decoration: none;
}

.menus li a {
    /* color: #000 !important; */
    color: var(--black) !important;
    font-size: 16px;
}

.menus li {
    margin: 0px 10px;
}

.logo {
    padding-left: 25px;
}

.btn-part a:hover {
    background: var(--red);
}

.banner-part img {
    width: 100%;
}

div#navbarScroll {
    padding: 13px 0px;
}

.banner-part {
    position: relative;
    background: url(../../../public/images/banner.jpg);
    height: 647px;
    background-position: center center;
    padding: 50px 0px;
    background-size: cover;
}

.banner-content h1 {
    font-size: 45px;
    color: var(--blue);
    font-weight: bold;
}

.banner-content h1 span {
    color: var(--greeen);

}

.banner-content {
    position: absolute;
    top: 59px;
    max-width: 700px;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
}

.left-entity {

    background: var(--greeen);
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0px;
    gap: 0px;
    border-radius: 10px 0px 0px 10px;
}

.select1 {
    display: flex;
    width: 100%;
    margin-bottom: 13px;
    align-items: center;
    position: relative;
    margin-left: 20px;
}

.select-part {
    position: absolute;
    top: 50%;
    max-width: 440px;
    width: 100%;
    left: 172px;
}



.packge-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}

.packge-btn1 button {
    background: transparent;
    margin-right: 5px;
    padding: 10px 13px;
    border: 1px solid var(--blue);
    color: var(--blue);
    border-radius: 4px;
}

.packge-btn2 button {
    background: var(--red);
    margin-right: 5px;
    padding: 10px 13px;
    border: 1px solid var(--red);
    color: var(--white);
    border-radius: 4px;
}

.box1 .box11:nth-child(1) {
    background: var(--red);
    padding: 25px 25px 25px 50px;
}

.box1 .box11:nth-child(2) {
    background: var(--blue);
    padding: 25px 25px 25px 50px;
}

.box1 .box11:nth-child(3) {
    background: var(--greeen);
    padding: 25px 25px 25px 50px;
}

.box1 .box11 {
    width: 33.33%;
}

.box1 {
    width: 100%;
    display: flex;
}

.box-icon img {
    width: 70%;
}

.box1 .box11 {
    width: 33.33%;
    display: flex;
    align-items: center;
}

.box-icon img {
    width: 31px;
}

.box-icon {
    padding-right: 20px;
}


.box-right p {
    line-height: 20px;
    color: var(--white);
    margin-bottom: 0px;
}

.comparion-part {
    background: var(--lightblue);
    padding: 100px 0px 0px 0px;
}

.comparion-part table tr th p {
    font-weight: 600;
}

.comparion-part table tr th span {
    color: #0A0A0A;
    font-size: 41px !important;
    font-weight: 600;
}

.comparion-part table tr th h5 {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}


.comparion-part table tr th div:after {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 20px solid var(--dark-blue);
    z-index: 9;
    position: absolute;
    content: "";
    bottom: -20px;
    margin: auto;
    left: 0;
    right: 0;
}

.comparion-part table tr th .fast-filling:after {
    border-top: 20px solid var(--orange);
}

.comparion-part table {
    border-collapse: separate;
    border-spacing: 0 .4em;
}

.comparion-part table tr td button {
    background: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: var(--blue);
}

.testmonial-icon img {
    width: 70px;
}

.testmonial-icon {
    position: absolute;
    right: 31px;
    bottom: -23px;
}

.box-part img {
    width: 100%;
}

.contant-box11 {
    background: #fff;
    padding: 15px;
    text-align: center;
}

.greent-box {
    padding: 180px 0px 30px 0px;
    background: var(--greeen);
}

.box-part {
    background: var(--lightblue);
    margin-bottom: -87px;
}

.box-part11 {
    position: relative;
    top: 70px;
}

.box-part11 h5 {
    font-size: 16px;
    font-weight: 600;
    padding-top: 13px;
}

.button-art1 {
    text-align: center;
    padding: 20px 0px;
}

.button-art1 button {
    background: var(--white);
    border: none;
    padding: 15px 25px;
    border-radius: 4px;
}

.mask-group img {
    width: 100%;
}
.testmoinial-box {
    margin-top: 20px;
}
.corp {
    padding: 50px 0px;
    background: var(--lightblue);
}

.heading-part {
    text-align: center;
    max-width: 1024px;
    margin: 0 auto;
}

.heading-part h2 {
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 600;
    color: var(--greeen);
}

.mask-group {
    margin-top: 50px;
}

.mask-content {
    background: var(--white);
    padding: 20px;
    text-align: center;
    box-shadow: 0px 0px 6px 0px #ddd;
    min-height: 205px;
}

.mask-content h5 {
    font-size: 18px;
    font-weight: 600;
}

.masn-btn button {
    border: none;
    background: transparent;
    border: none;
}

.masn-btn {
    text-align: center;
    background: var(--red);
    padding: 15px;
}

.masn-btn button {
    color: var(--white);
}

.comparion-part table tr th h2 {
    font-size: 27px;
    font-weight: 600;
    color: var(--greeen);
}



.stright-formward:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgb(0 0 0 / 71%);
}

.strainght-content h2 {
    line-height: 39px;
    color: var(--white);

}

.strainght-content {
    position: relative;
    z-index: 9;
}

.strainght-content p {
    color: var(--white);
    font-size: 14px;
}

.strainght-content {
    text-align: right;
}

.strainght-content button {
    background: var(--greeen);
    border: navajowhite;
    font-size: 14px;
    padding: 10px;
    color: var(--white);
    border-radius: 4px;
}

.strainght-content {
    padding-right: 35px;
    border-right: 1px solid #6d6c6c;
}

.box-stright {
    position: relative;
    z-index: 9;
}

.box-stright {
    padding: 20px 0px;
}

.strght-con1 {
    display: flex;
    align-items: center;
}

.left-stright img {
    width: 50px;
}

.right-stright h5 {
    margin-left: 20px;
    color: #fff;
    font-size: 15px;
}

.testimoial {
    padding: 100px 200px;
    background: var(--lightblue);
}

.review-part {
    display: flex;
    align-items: center;
    background: var(--white);
    /* padding: 20px; */
}

.client-content {
    padding: 0px 30px;
    position: relative;
}

.client-content img {
    margin-bottom: 20px;
}

.client-content h6 {
    font-weight: 300;
}

#demo .carousel-control-prev {
    bottom: -60px;
    top: auto;
    left: auto;
    right: 60px;
    background: #BFE3F0;
    width: 60px;
    height: 60px;
    opacity: 1;
}

#demo .carousel-control-next {
    bottom: -60px;
    top: auto;
    left: auto;
    right: 0px;
    background: #D81159;
    width: 60px;
    height: 60px;
    opacity: 1;
}

.footer-section {
    background: url(../../../public/images/backfooter.png);

    background-position: center;
}

.footerright {
    background: var(--red);
    padding: 30px 50px;
}

.footer-menu h5 {
    color: var(--white);
    font-family: 'Antonio';
    text-transform: uppercase;
}

.footer-menu h4 {
    font-size: 35px;
    color: var(--white);
    margin-top: 20px;
}

.footer-menu p {
    color: #fff;
    font-size: 14px;
}

.footer-menu ul {
    padding-left: 0px;
}

.footer-menu ul li {
    list-style: none;
    margin-top: 4px;
}

.footer-menu ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    position: relative;
    padding-left: 20px;
}

.footer-menu ul li a:before {
    content: "";
    background: url(../../../public/images/right-arrow.png);
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-size: 12px;
    position: absolute;
    top: 6px;
    left: 0;
}

.copy {
    padding: 15px 10px;
    text-align: center;
    background: var(--blue);
}

.copt-right p {
    margin-bottom: 0px;
    color: #fff;
}

.footer-logo {
    margin-top: 25px;
}

.footer-logo img {
    height: 300px;
}

.footer-logo {
    margin-top: 43px;
    margin-left: 12px;
}

nav.navbar.navbar-expand-lg.bg-body-tertiary.top-nav .container-fluid {
    padding-right: 0px !important;
}



/*form order css*/
.form-order {
    background-image: url(../../../public/images/loginback.jpg);
    background-position: center;
    background-size: cover;
    padding: 100px 0px 0px 0px;
}


/*login css start*/
section.login-part {
    background: url(../../../public/images/banner.jpg);
    /* height: 448px; */
    background-size: cover;
    background-position: right;
    padding: 150px 0px 88px 0px;
}

.signup-form {
    background: var(--white);
    padding: 40px;
    border-radius: 10px;
}

.signup-form input {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #d7d7d7;
    padding: 8px;
    font-size: 14px;
}

.signup-form input[type="submit"] {
    background: var(--red);
    color: var(--white);
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 20px;
}

.signup-form p a {
    color: #f00;
    font-weight: 600;
}

.signup-form p {
    text-align: right;
    font-size: 11px;
}

.signup-form h2 {
    margin-bottom: 30px;
}

.signup-form h2 {
    font-size: 35px;
    color: var(--blue);
    font-weight: bold;
}

.signup-form h2 span {
    color: var(--greeen);
}

/*login css end*/


/*form order css start*/
.form-sub {
    max-width: 820px;
    margin: 0 auto;
}

.banner-part .form-sub select {
    width: 400px !important;
    padding: 15px 45px;
}

.right-select label {
    padding-left: 42px;
    font-size: 14px;
    margin-bottom: 4px;
}

.form-sub .left-entity {
    top: 26px;
    padding: 17px 14px;
}

.select-box {
    margin-top: 10px;
    font-size: 14px;
}

.button11 button {
    background: var(--red) !important;
    color: #fff !important;
}

.minus-part h6 {
    font-size: 29px;
    color: var(--greeen);
}

.form11 {
    padding: 20px;
    background: var(--blue);
    border-radius: 8px;
}

.form12 {
    display: flex;
    justify-content: space-between;
    color: #fff;
}

.compare-1 .comparion-part1 {
    padding-top: 60px !important;
    padding-bottom: 80px;
}

.selected1 h4 {
    font-family: 'Poppins';
}

button.basic-btn1 {
    width: 150px;
}

.comparion-part1 table tr td button.btn-red-600 {
    background: var(--red);
    color: #fff;
}

th.active-header {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
    border-left: 1px solid var(--red) !important;
    border-right: 1px solid var(--red) !important;
    border-top: 1px solid var(--red) !important;
    padding: 3px !important;
    padding-bottom: 0px !important;
}

.active-column {
    border-left: 1px solid #FF4A00 !important;
    border-right: 1px solid #FF4A00 !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 80px !important;
    padding-right: 3px !important;
    padding-left: 3px !important;
}

.compare-1 .comparion-part1 table tr {
    border-bottom: 1px solid #ddd;
}

.compare-1 .comparion-part1 table {
    border-collapse: collapse;
    border-spacing: 0 .4em;
}

.active-header {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
    padding: 24px 12px !important;
    background: #FFF6F2 !important;
}

.active-column {
    background: #FFF6F2;
    padding-right: 3px !important;
    padding-left: 3px !important;
}

.comparion-part1 table tr th h2 {
    font-size: 27px;
    font-weight: 600;
    color: var(--greeen);
}

.comparion-part1 table tr th span {
    color: #0A0A0A;
    font-size: 41px !important;
    font-weight: 600;
}

.comparion-part1 table tr th h5 {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}


.comparion-part1 table tr th p {
    font-weight: 400;
}

.comparion-part1 table tr td button.btn-red-600 {
    background: var(--red);
    color: #fff;
}

.comparion-part1 {
    background: var(--lightblue);
    padding: 100px 0px 0px 0px;
}

.comparion-part1 table tr td button {
    background: #fff;
    border: 1px solid #00aeef47;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: var(--blue);
    width: 80px;
    margin: 0 auto;
    box-shadow: 0px 0px 3px 0px #00aeef75;
}

.form-order-heading1 h1 {
    font-size: 45px;
    color: var(--blue);
    font-weight: bold;
}

.form-order-heading1 h1 span {
    color: var(--greeen);
}

.form-order-heading1 {
    margin-bottom: 40px;
}



button.basic-btn1 {
    background: #e2f7ff;
    border: none;
    padding: 10px;
    width: 150px;
}

.comparion-part .basic-btn2 {
    width: 169px !important;
}

button.basic-btn3 {
    background: #e2f7ff;
    border: none;
    padding: 10px;
    width: 150px;
}

.down-para {
    display: none;
}

button.downarrow1 img {
    width: 12px;
}

span.check-img {
    display: none;
}

button.downarrow1 {
    padding: 0px 0px !important;
    color: #000 !important;
    font-weight: 400 !important;
}

.btn-red-600 {
    background: var(--red) !important;
    border: none !important;
    color: #fff !important;
    width: 150px !important;
    padding: 10px !important;
}

/*form order css end*/



.toggle-button {
    font-size: 24px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    border: none;
    background-color: #f0f0f0;
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    /* Added margin for better spacing between buttons */
}

.toggle-button .icon {
    display: none;
    font-size: 24px;

}

.select-box button {
    width: 156px !important;
}

.toggle-button .plus-icon {
    display: inline;
}

.toggle-button.active .plus-icon {
    display: none;
}

.toggle-button.active .check-icon {
    display: inline;
    width: 15px;
    height: 15px;
}

.toggle-button:focus {
    outline: none;
}

/* New Style */
.custom-accordion {}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    color: #000;
    background: none;
}

.custon-label-part.label-part {
    justify-content: flex-start;
}

.custon-label-part.label-part i {
    position: relative;
    left: 13px;
    top: 5px;
}

.errorMsg {
    color: #df1c41;
    font-size: 12px;
    font-weight: 400;
    /* display: none; */
}

.divider {
    background: #d5d5d5;
    height: 1px;
    margin-top: 25px;
}
.dynamic-div form .form-group {
    margin-bottom: 15px;
}

.streetAddress-group1,
.streetAddress-group2,
.streetAddress-group3,
.streetAddress-group4,
.streetAddress-group5,
.streetAddress-group6 {
    display: none;
}

.address-group1,
.address-group2,
.address-group3,
.address-group4,
.address-group5,
.address-group6 {
    display: none;
}

.city-group1,
.city-group2,
.city-group3,
.city-group4,
.city-group5,
.city-group6 {
    display: none;
}

.state-group1,
.state-group2,
.state-group3,
.state-group4,
.state-group5,
.state-group6 {
    display: none;
}

.zip-code-group1,
.zip-code-group2,
.zip-code-group3,
.zip-code-group4,
.zip-code-group5,
.zip-code-group6 {
    display: none;
}

.representative-signing1,
.representative-signing2,
.representative-signing3,
.representative-signing4,
.representative-signing5,
.representative-signing6 {
    display: none;
}

.company-name1,
.company-name2,
.company-name3,
.company-name4,
.company-name5,
.company-name6 {
    display: none;
}

.twoBox {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(295px, 1fr));
    gap: 14px;
}

.border-dashed {
    border-style: dashed;
}

#myForm label {
    color: #121212;
    font-size: 14px;
    font-weight: 600;
}

.custom_radio {
    display: flex;
    justify-content: space-between;
}

.custom_radio input[type="radio"] {
    display: none;
}

.custom_radio input[type="radio"]+label span {
    display: block;
    font-weight: 500;
    font-size: 13px;
}
.custom_radio.mb25.radio-btn11.process-agentbtn span {
    font-size: 12px;
}
.custom_radio input[type="radio"]+label {
    position: relative;
    display: inline-block;
    padding-left: 25px;
    padding-top: 8px;
    padding-right: 10px;
    padding-bottom: 8px;
    cursor: pointer;
    line-height: 1em;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
    color: #121212;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.custom_radio input[type="radio"]:checked+label {
    border-color: #ff8800;
}

.custom_radio input[type="radio"]+label:before,
.custom_radio input[type="radio"]+label:after {
    content: '';
    position: absolute;
    top: 15px;
    left: 5px;
    width: 1em;
    height: 1em;
    text-align: center;
    color: white;
    border: 1px solid #ddd;
    font-family: Times;
    border-radius: 50%;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.custom_radio input[type="radio"]+label:before {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em white;
}

.custom_radio input[type="radio"]+label:hover:before {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.3em white, inset 0 0 0 1em #c6c6c6;
}

.custom_radio input[type="radio"]:checked+label:before {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.2em white, inset 0 0 0 1em #ff8800;
}

.info-blk-company-address {
    display: flex;
    gap: 15px;
    background: #d3ecf3;
    padding: 15px;
    border-radius: 8px;
    margin-top: 15px;
    margin-bottom: 15px;
    /* display: none; */
}

.info-blk-company-address h5 {
    color: rgb(19, 78, 74);
    font-size: 14px;
    font-weight: 600;
}

.info-blk-company-address p {
    color: rgb(19, 78, 74);
    font-size: 14px;
    margin-bottom: 0;
}

.company-name,
.representative-signing {
    display: none;
}

.memberblk {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}


.register-left .other-information h4 {
    font-family: 'Antonio';
}

.left-next button {
    background: var(--red);
    margin-right: 5px;
    padding: 10px 20px;
    border: 1px solid var(--red);
    color: var(--white);
    text-decoration: none;
}
.left-next button i {
    padding-right: 5px;
}
.checl-box12 input[type="checkbox"] {
    position: absolute;
    appearance: revert !important;
    -webkit-appearance: revert !important;
    -moz-appearance: revert !important;
    width: 100%;
}
.checl-box12 p {
    padding-left: 25px;
    padding-top: 4px;
}
ol.list-unstyled.list-agent {
    list-style: auto;
    padding-left: 20px;
}
div#selectedFreeYear {
    margin-top: 20px;
}
div#agentInformation {
    background: #ffff;
    margin-top: 20px;
    padding: 40px;
    border-radius: 10px;
}
div#agentInformation label {
    margin-top: 13px;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 600;
}
.register-left label {
    font-weight: bold;
}
.form-standard-process .form-group {
    margin-top: 15px;
}
.twoBox.form-standard-process label {
    margin-bottom: 5px;
    font-size: 14px;
}
.custom_radio.identification-type input[type="radio"]+label:before, .custom_radio.identification-type input[type="radio"]+label:after {top: 11px !important;}
.identification1 {margin-bottom: 10px;font-weight: 600;}
form#ss4Country label {margin-bottom: 10px;margin-top: 10px;font-size: 14px;font-weight: 600;color:#121212;}
.box__heading1.please-provide {margin-bottom: 9px;}
.member-label.number-member { margin-bottom: 10px;font-weight: 600;}
.custum-radio1 .custom_radio input[type="radio"]+label {padding-right: 96px;}
.custum-checkbox.check1 {
    display: flex;
    position: relative;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(72, 72, 72, .16), 0 0 0 1px rgba(72, 72, 72, .16), 0 1px 2px 0 hsla(0, 0%, 41%, .08), 0 0 0 1px rgba(72, 72, 72, .13);
    margin-top: 10px;
    padding: 10px 0px 0px 10px;
}
.custum-checkbox.check1 p {margin-bottom: 0px;font-size: 14px;}
.twoBox {margin-top: 15px;}
.twoBox label {font-weight: 600;font-size: 14px;color: #121212;margin-bottom: 10px;}
.custum-checkbox.check1 .right-checkbox {padding-left: 0px;}
.twoBox input[type="text"] {padding: 10px;font-size: 14px;}
button.edit-button { padding: 7px 18px;background: var(--greeen);color: var(--white);font-weight: 600;}
.form-group.ownershipPercentage2 {margin-top: 15px;}
button.edit-button {padding: 7px 18px;background: var(--greeen);color: var(--white);font-weight: 600;border: none;}
.member-label.number-member {display: flex;justify-content: space-between;margin-top: 30px;margin-bottom: 30px;background: #f7f7f782;padding: 6px 7px;align-items: center;}
.company1 label {font-weight: 600;font-size: 14px;color: #121212;margin-bottom: 10px;}
.company1 {margin-top: 20px;}
.custum-radio1 {margin-bottom: 20px;}
.ownership label {margin-bottom: 10px;font-weight: 600;font-size: 14px;}
.ownership {margin-top: 20px;}
a.disclaimer {font-weight: 600;color: var(--orange);}
.right-next.nex2.ask-later {margin-top: 30px;}
.right-next.nex2.ask-later button {background-color: var(--greeen);border: none;}
.box__heading1.tax-strategy {font-weight: 600;margin-bottom: 15px;}
h5.learn {margin-bottom: 20px;font-weight: 600;}
.learn-list ul li i {position: absolute;left: 0;top: 4px;color: var(--orange);}
.learn-list ul li {position: relative;padding-left: 27px;margin-bottom: 12px;}
.radio22 .custom_radio input[type="radio"]+label:before, .custom_radio input[type="radio"]+label:after {top: 10px;}
.licenses {padding-left: 20px;}
.business-ownwes-blk ul li i {position: absolute;left: 0;top: 4px;color: var(--orange);}
.business-ownwes-blk ul li {position: relative;padding-left: 25px;}
.custumnew {margin-top: 30px;}
.custumnew .custom_radio input[type="radio"]+label:before, .custom_radio input[type="radio"]+label:after {top: 10px;}
.custom_radio input[type="radio"]+label:before,.custom_radio input[type="radio"]+label:after {top: 10px;}
.process-review .box__heading1 {font-weight: 600;margin-bottom: 10px;}
.review-box {border: 1px solid #E7E7E7;margin-top: 20px;padding: 20px;border-radius: 8px;}
.formation1 {padding-bottom: 13px;margin-bottom: 17px;font-weight: 600;}
.formation-info {display: grid;grid-template-columns: repeat(2, minmax(0, 1fr));margin-top: 20px;}
.review-info {display: flex;justify-content: space-between;overflow: hidden;clear: both;border-bottom: 1px dashed #E7E7E7;}
.review-info a {font-weight: 600;color: var(--black);}
.bg-accent-aqua-0 {box-shadow: 0px 0px 0px 1px #39b54a4f, 0px 1px 2px 0px #39b54a52, 0px 12px 24px -12px #39b54a82;background: #39b54a14;padding: 10px 13px;border-radius: 10px;text-align: center;margin-top: 20px;margin-bottom: 20px;font-weight: 600;}
.progress .progress-bar {text-align: left;padding-left: 20px;}
input#amount {max-width: 550px;}
button.payment-btn {padding: 0px 13px;}
h5.additional-info {padding: 10px 15px;font-weight: 600;font-size: 17px;}
input#amount {border: 1px solid #ddd;}
.box__heading1.business-banking {
    font-weight: bold;
    margin-bottom: 10px;
}
ul.list-unstyled li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 6px;
}
ul.list-unstyled li i {
    position: absolute;
    left: 0;
    top: 5px;
    color: var(--blue);
}

.formation-info .label-part {
    font-weight: 600;
}
.register-left .box__heading1 {
    font-weight: 600;
}
.payment-btn {
    position: relative;
}
.comparion-part tr th:empty {
    border: none;
}
.comparion-part table th:nth-child(2) .table-btn,
.comparion-part table th:nth-child(3) .table-btn {
    background-color: var(--dark-blue);
}

/* Third .table-btn background color yellow */
.comparion-part table th:nth-child(4) .table-btn {
    background-color: var(--orange);
}
.comparion-part table tr th:nth-child(4) .table-btn:after {

    border-top: 20px solid var(--orange) !important;

}
/* First and second button background color red */
.comparion-part table .basic-btn2 {
    background-color: var(--dark-blue) !important;
    color: white; /* Optional: Ensures text is visible */
}

/* Third button background color yellow */
.comparion-part table th:nth-child(4) .basic-btn2 {
    background-color: var(--orange) !important;
    /* Optional: Ensures text is readable */
}

.payment-error {
    position: absolute;
    bottom: -22px;
    font-size: 14px;
}



@media only screen and (max-width:768px) {
    .box1 .box11 {
        width: 100%;
        flex-direction: column;
    }
    .payment-error {
        bottom: -20px;
        font-size: 9px;
    }
    .large-box {
        margin-top: 10px;
        min-height: auto;
    }
    .box__heading {
        margin-bottom: 10px;}
    .form-order-heading1 h1 {
        font-size: 25px;}
        .form-order {
            padding: 20px 0px 0px 0px;
        }   
    .packge-btn a {
        font-size: 14px;
    }
    .packge-btn1 a {
        padding: 14px 15px;}
    .checl-box12 input[type="checkbox"] {
        left: -48%;
    }
    .box1 {
        flex-direction: column;
    }

    .box1 .box11 {
        text-align: center;
    }

    .box-right p {
        margin-top: 10px;
    }

    .banner-part {
        height: auto;
        background-position: inherit;
        padding: 50px 0px;

    }

    .banner-content h1 {
        font-size: 20px;
    }

    .banner-content {
        position: inherit;
        top: 0;
        padding: 0px 10px;
        text-align: center;
    }

    .select-part {
        position: inherit;
        top: 0;
        max-width: 100%;
        width: 100%;
        left: 0;
    }

 

    .packge-btn {
        justify-content: space-around;
    }

    .comparion-part {
        padding: 40px 0px 0px 0px;
    }

    .comparion-part table tr th h2 {
        font-size: 20px;
        text-align: center;
    }

    .corp {
        padding: 40px 0px;
    }

    .heading-part h2 {
        font-size: 25px;
    }

    .strainght-content {
        text-align: center;
        padding-right: 0px !important;
        border-right: none !important;
    }

    .strght-con1 {
        align-items: center;
        flex-direction: column;
    }

    .strght-con1 {
        margin-bottom: 30px;
    }

    .box-stright {
        padding: 0px 0px;
    }

    .right-stright h5 {
        margin-left: 0px;
        margin-top: 10px;
        font-size: 15px;
    }

    .strainght-content button {
        margin-bottom: 40px;
    }

    .testimoial {
        padding: 40px 15px 100px 0px;
    }

    .review-part {
        display: flex;
        flex-direction: column;
    }

    .footer-logo {
        display: none;
    }

    .footerright {
        padding: 30px 30px;
    }

    .select1 {
        margin-left: 0px;
        padding: 0px;
    }

    .select-part {
        padding: 0px 15px;
    }

    nav.navbar.navbar-expand-lg.bg-body-tertiary.top-nav .container-fluid {
        padding-right: 15px !important;
    }

    .logo {
        padding-left: 0px;
    }

    .btn-part {
        display: flex;
        align-items: center;
        position: absolute;
        top: -49px;
        right: 0;
        margin: 0 auto;
    }

    .phone-part {
        position: absolute;
        top: -51px;
        font-size: 14px;
    }

    .phone1-icon img {
        width: 14px;
    }

    .top-nav {
        padding: 11px 0px;
        margin-top: 64px;
    }

    .login-btn a {
        padding: 15px 20px;
    }

    .Started a {
        padding: 15px 20px;
    }

    div#navbarScroll {
        position: absolute;
        top: 71px;
        background: var(--lightblue);
        left: 0;
        right: 0;
        z-index: 9;
    }
    .button-nest-prev {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .registration-from {
        padding: 30px 0px;
    }
    .register-right {
        margin-top: 10px;
    }
    .register-left {
        padding: 10px 20px;

    }
    .register-right {
        padding: 10px 20px;
    }
    .register-left .form-group {
        margin-top: 10px;
    }
    .navbar  .phone-part {
        display: none;
    }
    .navbar  .btn-part{
        left: 0;
        justify-content: center;
    }
    .comparion-part table th {
        min-width: 230px;
        max-width: 230px;
    }
    .comparion-part1 table th{
        min-width: 210px;
        max-width: 210px;
    }
    .form-order .form-sub .right-select {
        width: 100%;
    }
    .custum-radio1 .custom_radio {
  
        flex-direction: column;
        width: 100%;
        gap: 10px;
    }
    .custum-radio1 .custom_radio input[type="radio"]+label {
        position: relative;
        display: block;
        }
        .custom_radio.radio-btn11 {

            flex-direction: column;
            gap: 10px;
        }   
        .custom_radio.radio-btn11 input[type="radio"]+label {
            position: relative;
            display: block;}
            #agentInformation .custom_radio {
                flex-direction: column;
            } 
            div#agentInformation {
          
                padding: 40px 20px;} 
                div#agentInformation .custom_radio input[type="radio"]+label {
                    position: relative;
                    display: block;}  
                    .foreign-individual, .identification-type {
                        gap: 0;
                    }
                    .payment-btn input#amount {
                        width: auto !important;
                    }
                    .mask-box {
                        margin-top: 10px;
                    }
                    .mask-content {

                        min-height: auto;
                    }
                    .box-stright {
                        text-align: center;
                    }
                    .left-stright img {
                        height: 35px;
                        object-fit: contain;
                    }
                    
                    
                    
                           
}