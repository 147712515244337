:root {
    --blue: #00AEEF;
    --greeen: #39B54A;
    --white: #ffffff;
    --red: #D81159;
    --black: #000000;
    --orange: #FF8800;
    --dark-blue: #0083F5;
    --lightblue: #e2f7ff;
}

body {
    font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4 {
    font-family: "Antonio", sans-serif;
}

div#navbarScroll ul {
    text-align: center;
    margin: 0 auto;
}

.phone-part {
    display: flex;
    align-items: center;
}

.btn-part {
    display: flex;
    align-items: center;
}

.login-btn a {
    padding: 24px 20px;
    background-color: var(--blue);
    color: var(--white);
    text-decoration: none;
}
.custom-dropdown button {
    width: 150px;
    height: 62px;
    background-color: var(--blue) !important;
    color: var(--white) !important;
    text-decoration: none !important;
    border: none !important;
    border-radius:0px !important;
    margin-right: 1px;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis
}

.Started a {
    padding: 24px 20px;
    background-color: var(--greeen);
    color: var(--white);
    text-decoration: none;
}

.login-btn {
    margin-right: 1px;
}

.top-nav {
    padding: 0px 0px;
}

.phone-part {
    margin-right: 10px;
}

.phone1-icon {
    padding-right: 10px;
}

.phone1-text a {
    color: var(--red);
    text-decoration: none;
}

.menus li a {
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-size: 17px;
    color:var(--black);
}

.menus li {
    margin: 0px 15px;
}

.logo {
    padding-left: 25px;
}

.btn-part a:hover {
    background: var(--red);
}

.banner-part img {
    width: 100%;
}

div#navbarScroll {
    padding: 12px 0px;
}

.banner-part {
    position: relative;
    background: url(../../../public/images/banner.jpg);
    height: 647px;
    background-position: center center;
    padding: 50px 0px;
    background-size: cover;
}

.banner-content h1 {
    font-size: 45px;
    color: var(--blue);
    font-weight: bold;
}

.banner-content h1 span {
    color: var(--greeen);

}

.banner-content {
    position: absolute;
    top: 59px;
    max-width: 700px;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center !important;
}
.banner-content p {
    text-align: center !important;
}
.packge-btn1 a {
    background: transparent;
    margin-right: 5px;
    padding: 14px 13px;
    border: 1px solid var(--blue);
    color: var(--blue);
    border-radius: 4px;
    text-decoration: none;
}
.comparion-part table tr td h6 {
    font-weight: 600 !important;}
    .button-art1 a {
        background: var(--red);
        border: none;
        padding: 15px 25px;
        border-radius: 4px;
        text-decoration: none;
        color: var(--white);
    }   
    .button-art1 a:hover {
        background: var(--white);
        color: var(--black);
    }
.packge-btn2 a {
    background: var(--red);
    margin-right: 5px;
    padding: 14px 13px;
    border: 1px solid var(--red);
    color: var(--white);
    border-radius: 4px;
    text-decoration: none;
}
.left-entity {

    background: var(--greeen);
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0px;
    gap: 0px;
    border-radius: 10px 0px 0px 10px;
}

.select1 {
    display: flex;
    width: 100%;
    margin-bottom: 13px;
    align-items: center;
    position: relative;
    margin-left: 20px;
}

.select-part {
    position: absolute;
    top: 50%;
    max-width: 440px;
    width: 100%;
    left: 172px;
}

.select-part select {
    width: 359px;
    padding: 11px;
    margin-left: 59px;
    font-size: 14px;
    border-radius: 10px;
    color: var(--black);
    padding-left: 20px;
}

.packge-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}

.packge-btn1 button {
    background: transparent;
    margin-right: 5px;
    padding: 10px 13px;
    border: 1px solid var(--blue);
    color: var(--blue);
    border-radius: 4px;
}

.packge-btn2 button {
    background: var(--red);
    margin-right: 5px;
    padding: 10px 13px;
    border: 1px solid var(--red);
    color: var(--white);
    border-radius: 4px;
}

.box1 .box11:nth-child(1) {
    background: var(--red);
    padding: 25px 25px 25px 50px;
}

.box1 .box11:nth-child(2) {
    background: var(--blue);
    padding: 25px 25px 25px 50px;
}

.box1 .box11:nth-child(3) {
    background: var(--greeen);
    padding: 25px 25px 25px 50px;
}

.box1 .box11 {
    width: 33.33%;
}

.box1 {
    width: 100%;
    display: flex;
}

.box-icon img {
    width: 70%;
}

.box1 .box11 {
    width: 33.33%;
    display: flex;
    align-items: center;
}

.box-icon img {
    width: 31px;
}

.box-icon {
    padding-right: 20px;
}


.box-right p {
    line-height: 20px;
    color: var(--white);
    margin-bottom: 0px;
}

.comparion-part {
    background: var(--lightblue);
    padding: 100px 0px 0px 0px;
}

.comparion-part table tr th p {
    font-weight: 400;
}

.comparion-part table tr th span {
    color: #0A0A0A;
    font-size: 41px !important;
    font-weight: 600;
}

.comparion-part table tr th h5 {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}

.comparion-part table tr th div {
    padding: 10px 0px;
    background: #ddd;
    padding: 21px 22px;
    margin: 0px 8px;
    text-align: center;
    font-weight: 400;
    position: relative;
    line-height: 22px;
}

.comparion-part table tr th div:after {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 20px solid var(--dark-blue);
    z-index: 9;
    position: absolute;
    content: "";
    bottom: -20px;
    margin: auto;
    left: 0;
    right: 0;
}

.comparion-part table tr th .fast-filling:after {
    border-top: 20px solid var(--orange);
}

.comparion-part table {
    border-collapse: separate;
    border-spacing: 0 .4em;
}

.comparion-part table tr td button {
    background: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: var(--blue);
}

.testmonial-icon img {
    width: 70px;
}

.testmonial-icon {
    position: absolute;
    right: 31px;
    bottom: -23px;
}

.box-part img {
    width: 100%;
}

.contant-box11 {
    background: #fff;
    padding: 15px;
    text-align: center;
}

.greent-box {
    padding: 180px 0px 30px 0px;
    background: var(--greeen);
}

.box-part {
    background: var(--lightblue);
    margin-bottom: -87px;
}

.box-part11 {
    position: relative;
    top: 70px;
}

.box-part11 h5 {
    font-size: 16px;
    font-weight: 600;
    padding-top: 13px;
}

.button-art1 {
    text-align: center;
    padding: 20px 0px;
}

.button-art1 button {
    background: var(--white);
    border: none;
    padding: 15px 25px;
    border-radius: 4px;
}

.mask-group img {
    width: 100%;
}

.corp {
    padding: 100px 0px;
    background: var(--lightblue);
}

.heading-part {
    text-align: center;
    max-width: 1024px;
    margin: 0 auto;
}

.heading-part h2 {
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 600;
    color: var(--greeen);
}

.mask-group {
    margin-top: 50px;
}

.mask-content {
    background: var(--white);
    padding: 20px;
    text-align: center;
    box-shadow: 0px 0px 6px 0px #ddd;
    min-height: 180px;
}

.mask-content h5 {
    font-size: 18px;
    font-weight: 600;
}

.masn-btn button {
    border: none;
    background: transparent;
    border: none;
}

.masn-btn {
    text-align: center;
    background: var(--red);
    padding: 15px;
}

.masn-btn button {
    color: var(--white);
}

.comparion-part table tr th h2 {
    font-size: 27px;
    font-weight: 600;
    color: var(--greeen);
}

.stright-formward {
    background: url(../../../public/images/back.png);
    position: relative;
    padding: 70px 0px;
    background-size: cover;

}
.client-content img {
    margin-top: 20px;
}

.stright-formward:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgb(0 0 0 / 71%);
}

.strainght-content h2 {
    line-height: 39px;
    color: var(--white);

}

.strainght-content {
    position: relative;
    z-index: 9;
}

.strainght-content p {
    color: var(--white);
    font-size: 14px;
}

.strainght-content {
    text-align: right;
}

.strainght-content button {
    background: var(--greeen);
    border: navajowhite;
    font-size: 14px;
    padding: 10px;
    color: var(--white);
    border-radius: 4px;
}

.strainght-content {
    padding-right: 35px;
    border-right: 1px solid #6d6c6c;
}

.box-stright {
    position: relative;
    z-index: 9;
}

.box-stright {
    padding: 20px 0px;
}

.strght-con1 {
    display: flex;
    align-items: center;
}

.left-stright img {
    width: 50px;
}

.right-stright h5 {
    margin-left: 20px;
    color: #fff;
    font-size: 15px;
}

.testimoial {
    padding: 100px 200px;
    background: var(--lightblue);
}

.review-part {
    display: flex;
    align-items: center;
    background: var(--white);
    /* padding: 20px; */
}

.client-content {
    padding: 0px 30px;
    position: relative;
}

.client-content img {
    margin-bottom: 20px;
}

.client-content h6 {
    font-weight: 300;
}

#demo .carousel-control-prev {
    bottom: -60px;
    top: auto;
    left: auto;
    right: 60px;
    background: #BFE3F0;
    width: 60px;
    height: 60px;
    opacity: 1;
}

#demo .carousel-control-next {
    bottom: -60px;
    top: auto;
    left: auto;
    right: 0px;
    background: #D81159;
    width: 60px;
    height: 60px;
    opacity: 1;
}

.footer-section {
    background: url(../../../public/images/backfooter.png);

    background-position: center;
}

.footerright {
    background: var(--red);
    padding: 30px 50px;
}

.footer-menu h5 {
    color: var(--white);
    font-family: 'Antonio';
    text-transform: uppercase;
}

.footer-menu h4 {
    font-size: 35px;
    color: var(--white);
    margin-top: 20px;
}

.footer-menu p {
    color: #fff;
    font-size: 14px;
}

.footer-menu ul {
    padding-left: 0px;
}

.footer-menu ul li {
    list-style: none;
    margin-top: 4px;
}

.footer-menu ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    position: relative;
    padding-left: 20px;
}

.footer-menu ul li a:before {
    content: "";
    background: url(../../../public/images/right-arrow.png);
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-size: 12px;
    position: absolute;
    top: 6px;
    left: 0;
}

.copy {
    padding: 15px 10px;
    text-align: center;
    background: var(--blue);
}

.copt-right p {
    margin-bottom: 0px;
    color: #fff;
}

.footer-logo {
    margin-top: 25px;
}

.footer-logo img {
    height: 300px;
}

.footer-logo {
    margin-top: 43px;
    margin-left: 12px;
}

nav.navbar.navbar-expand-lg.bg-body-tertiary.top-nav .container-fluid {
    padding-right: 0px !important;
}


@media only screen and (max-width:768px) {
    .box1 .box11 {
        width: 100%;
        flex-direction: column;
    }
    .custom-dropdown button {
        width: 150px;
        height: 48px;}
    .box1 {
        flex-direction: column;
    }

    .box1 .box11 {
        text-align: center;
    }

    .box-right p {
        margin-top: 10px;
    }

    .banner-part {
        height: auto;
        background-position: inherit;
        padding: 50px 0px;

    }

    .banner-content h1 {
        font-size: 24px;
    }

    .banner-content {
        position: inherit;
        top: 0;
        padding: 0px 10px;
        text-align: center;
    }

    .select-part {
        position: inherit;
        top: 0;
        max-width: 100%;
        width: 100%;
        left: 0;
    }

    .select-part select {
        width: 270px;
    }

    .packge-btn {
        justify-content: center;
    }

    .comparion-part {
        padding: 40px 0px 0px 0px;
    }

    .comparion-part table tr th h2 {
        font-size: 20px;
        text-align: center;
    }

    .corp {
        padding: 40px 0px;
    }

    .heading-part h2 {
        font-size: 30px;
    }

    .strainght-content {
        text-align: center;
        padding-right: 0px !important;
        border-right: none !important;
    }

    .strght-con1 {
        align-items: center;
        flex-direction: column;
    }

    .strght-con1 {
        margin-bottom: 30px;
    }

    .box-stright {
        padding: 0px 0px;
    }

    .right-stright h5 {
        margin-left: 0px;
        margin-top: 10px;
        font-size: 15px;
    }

    .strainght-content button {
        margin-bottom: 40px;
    }

    .testimoial {
        padding: 40px 15px 100px 0px;
    }

    .review-part {
        display: flex;
        flex-direction: column;
    }

    .footer-logo {
        display: none;
    }

    .footerright {
        padding: 30px 30px;
    }

    .select1 {
        margin-left: 0px;
        padding: 0px;
    }

    .select-part {
        padding: 0px 15px;
    }

    nav.navbar.navbar-expand-lg.bg-body-tertiary.top-nav .container-fluid {
        padding-right: 15px !important;
    }

    .logo {
        padding-left: 0px;
    }

    .btn-part {
        display: flex;
        align-items: center;
        position: absolute;
        top: -49px;
        right: 0;
        margin: 0 auto;
    }

    .phone-part {
        position: absolute;
        top: -51px;
        font-size: 14px;
    }

    .phone1-icon img {
        width: 14px;
    }

    .top-nav {
        padding: 11px 0px;
        margin-top: 64px;
    }

    .login-btn a {
        padding: 15px 20px;
    }

    .Started a {
        padding: 15px 20px;
    }

    div#navbarScroll {
        position: absolute;
        top: 71px;
        background: var(--lightblue);
        left: 0;
        right: 0;
        z-index: 9;
    }
}

.custom-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content .dropdown-item {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content .dropdown-item:hover {
    background-color: #f1f1f1;
  }
  
  /* .btn-part .btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
    color: white;
  } */
  
  .btn-part .btn-secondary:hover {
    background-color: #5a6268;
    border-color: #545b62;
  }
  .navbar-nav li a {
    color: var(--black) !important;
    font-size: 16px;
}


@media only screen and (max-width:768px){
    .banner-part .select1 {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
    }
    .banner-part .left-entity {
        width: 20%;
        text-align: left;
        font-weight: bold;
    }
    .banner-part .right-select {
        width: 80%;
    }
    .banner-part .right-select select {
        width: 100%;
        padding: 10px 26px;
        font-size: 15px;
    }
    .comparion-part table tr th p {
        font-weight: 500;
        font-size: 12px;
    }
    .stright-formward {
        padding: 30px 0px;
    }
    .strainght-content h2 {
        font-size: 20px;
    }
   
}
  